<template>
  <div class="page-bg-gradient relative min-h-full">
    <div class="h-44px"></div>
    <div
      class="h-48px text-center text-23px font-bold font-Gilroy leading-44px"
    >
      NFT
    </div>
    <div
      class="
        absolute
        top-33px
        right-0
        w-138px
        h-207px
        bg-[#5ca5e480]
        filter
        blur-100px
      "
    ></div>
    <div class="mt-16px px-24px">
      <div class="w-full h-180px box rounded-24px">
        <img
          class="w-240px -mt-30px mx-auto"
          src="~@/assets/new/nft-demo1.png"
          alt=""
          srcset=""
        />
      </div>
      <a class="w-full" :href="`${nfturl}/my-nft`">
        <div
          class="
            mt-16px
            h-44px
            leading-44px
            text-center
            rounded-16px
            text-15px
            bg-gradient-to-r
            to-[#1453bf]
            from-[#24d68e]
          "
        >
          {{ $t("mynft") }}
        </div>
      </a>
      <div class="mt-24px py-1px">

        <!-- <div
          class="bg-[#ffffff1a] rounded-24px p-20px mb-16px"
        >
          <div class="text-21px font-medium">{{$t('bc')}}</div>
          <div class="flex justify-between text-15px py-6px mt-10px">
            <div class="text-[#ffffffb3]">{{ $t("head.reward") }}</div>
            <div>{{compensate}} UNC</div>
          </div>


          <div class="flex mt-10px">
            <div
              @click="getCompensate"
              class="
                h-34px
                flex
                items-center
                justify-center
                flex-1
                rounded-12px
                bg-gradient-to-l
                from-[#2cba814d] from-opacity-30
                to-[#1453bf4d] to-opacity-30
                border border-[#1453bf4d]
                mybtn
              "
            >
              <img
                src="~@/assets/new/21.png"
                class="w-16px h-16px mr-6px"
                alt=""
                srcset=""
              />
              <span>{{ $t("head.get") }}</span>
            </div>
          </div>
        </div> -->

       
        
        <div
          class="bg-[#ffffff1a] rounded-24px p-20px mb-16px"
        >
          <div class="text-21px font-medium">{{$t('v1banlace')}}</div>
          <div class="flex justify-between text-15px py-6px mt-10px">
            <div class="text-[#ffffffb3]">{{ $t("head.reward") }}</div>
            <div>{{ v1balancereward }} UNC</div>
          </div>


          <div class="flex mt-10px">
            
            <div
              @click="onWithdrawV1Balance"
              class="
                ml-10px
                h-34px
                flex
                items-center
                justify-center
                flex-1
                rounded-12px
                bg-gradient-to-l
                from-[#2cba814d] from-opacity-30
                to-[#1453bf4d] to-opacity-30
                border border-[#1453bf4d]
                mybtn
              "
            >
              <img
                src="~@/assets/new/21.png"
                class="w-16px h-16px mr-6px"
                alt=""
                srcset=""
              />
              <span>{{ $t("head.get") }}</span>
            </div>
            
          </div>
        </div>


         <div
          class="bg-[#ffffff1a] rounded-24px p-20px mb-16px"
        >
          <div class="text-21px font-medium">LP {{$t('fh')}}</div>
          <div class="flex justify-between text-15px py-6px mt-10px">
            <div class="text-[#ffffffb3]">{{ $t("head.reward") }}</div>
            <div>{{lpreward}} UNC</div>
          </div>


          <div class="flex mt-10px">
            <div
              @click="onUpdatLpReward"
              class="
                h-34px
                flex
                items-center
                justify-center
                flex-1
                rounded-12px
                bg-gradient-to-l
                to-[#1453bf]
                from-[#24d68e]
                border border-[#1453bf4d]
                mybtn
              "
            >
              <img
                src="~@/assets/new/21.png"
                class="w-16px h-16px mr-6px"
                alt=""
                srcset=""
              />
              <span>{{ $t("head.update") }}</span>
            </div>
            <div
              @click="onWithdrawLpReward"
              class="
                ml-10px
                h-34px
                flex
                items-center
                justify-center
                flex-1
                rounded-12px
                bg-gradient-to-l
                from-[#2cba814d] from-opacity-30
                to-[#1453bf4d] to-opacity-30
                border border-[#1453bf4d]
                mybtn
              "
            >
              <img
                src="~@/assets/new/21.png"
                class="w-16px h-16px mr-6px"
                alt=""
                srcset=""
              />
              <span>{{ $t("head.get") }}</span>
            </div>
            
          </div>
        </div>
        
        <div
          class="bg-[#ffffff1a] rounded-24px p-20px mb-16px"
        >
          <div class="text-21px font-medium">{{$t('csnft')}} {{$t('fh')}}</div>
          <div class="flex justify-between text-15px py-6px mt-10px">
            <div class="text-[#ffffffb3]">{{ $t("head.reward") }}</div>
            <div>{{nft1reward}} UNC</div>
          </div>


          <div class="flex mt-10px">
            
            <div
              @click="onWithdrawNFT1Reward"
              class="
                ml-10px
                h-34px
                flex
                items-center
                justify-center
                flex-1
                rounded-12px
                bg-gradient-to-l
                from-[#2cba814d] from-opacity-30
                to-[#1453bf4d] to-opacity-30
                border border-[#1453bf4d]
                mybtn
              "
            >
              <img
                src="~@/assets/new/21.png"
                class="w-16px h-16px mr-6px"
                alt=""
                srcset=""
              />
              <span>{{ $t("head.get") }}</span>
            </div>
            
          </div>
        </div>


         <div
          class="bg-[#ffffff1a] rounded-24px p-20px mb-16px"
        >
          <div class="text-21px font-medium">{{$t('lmnft')}} {{$t('fh')}}</div>
          <div class="flex justify-between text-15px py-6px mt-10px">
            <div class="text-[#ffffffb3]">{{ $t("head.reward") }}</div>
            <div>{{nft2reward}} UNC</div>
          </div>


          <div class="flex mt-10px">
            <div
              @click="onUpdatNFT2Reward"
              class="
                h-34px
                flex
                items-center
                justify-center
                flex-1
                rounded-12px
                bg-gradient-to-l
                to-[#1453bf]
                from-[#24d68e]
                border border-[#1453bf4d]
                mybtn
              "
            >
              <img
                src="~@/assets/new/21.png"
                class="w-16px h-16px mr-6px"
                alt=""
                srcset=""
              />
              <span>{{ $t("head.update") }}</span>
            </div>
            <div
              @click="onWithdrawNFT2Reward"
              class="
                ml-10px
                h-34px
                flex
                items-center
                justify-center
                flex-1
                rounded-12px
                bg-gradient-to-l
                from-[#2cba814d] from-opacity-30
                to-[#1453bf4d] to-opacity-30
                border border-[#1453bf4d]
                mybtn
              "
            >
              <img
                src="~@/assets/new/21.png"
                class="w-16px h-16px mr-6px"
                alt=""
                srcset=""
              />
              <span>{{ $t("head.get") }}</span>
            </div>
            
          </div>
        </div>
        <!-- 锻造盲盒 -->
        <div
          class="bg-[#ffffff1a] rounded-24px p-20px mb-16px"
          v-for="(item, index) in list"
          :key="index"
        >
          <div class="text-21px font-medium">{{ item.name }}</div>
          <div class="flex justify-between text-15px py-6px mt-10px">
            <div class="text-[#ffffffb3]">{{ $t("head.hashPower") }}</div>
            <div>{{ item.x }}</div>
          </div>
          <div class="flex justify-between text-15px py-6px">
            <div class="text-[#ffffffb3]">{{ $t("head.overtime") }}</div>
            <div>4x</div>
          </div>

          <div class="flex mt-10px">
              <!-- @click="onApprove(item)" -->
            <div
              @click="onOver"
              :disabled="!!item.isMint"
              class="
                h-34px
                flex
                items-center
                justify-center
                flex-1
                rounded-12px
                bg-gradient-to-l
                from-[#2cba814d] from-opacity-30
                to-[#1453bf4d] to-opacity-30
                border border-[#1453bf4d]
                mybtn
              "
            >
              <img
                src="~@/assets/new/06.png"
                class="w-16px h-16px mr-6px"
                alt=""
                srcset=""
              />
              <span>{{ $t("head.approve") }}</span>
            </div>

              <!-- @click="oRelease(item)" -->
            <div
              @click="onOver"
              :disabled="!!item.isMint"
              class="
                ml-25px
                h-34px
                flex
                items-center
                justify-center
                flex-1
                rounded-12px
                bg-gradient-to-l
                from-[#2cba81]
                to-[#1453bf]
                mybtn
              "
            >
              <img
                src="~@/assets/new/21.png"
                class="w-16px h-16px mr-6px"
                alt=""
                srcset=""
              />
              <span>{{ $t("forging") }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <SuccessComp :show.sync="successShow"></SuccessComp>
  </div>
</template>
<script>
import mixin from "@/mixins/index";
import { BigNumber } from "bignumber.js";
import SuccessComp from './components/pushSuccessComp';
export default {
  mixins: [mixin],
  components:{
    SuccessComp
  },
  data() {
    return {
      successShow:false,
      wloading: false,
      current: "1",
      list:[],
      compensate:0,
      lpreward:0,
      nft1reward:0,
      nft2reward:0,
      v1balancereward:0,
      // list: [
      //   {
      //     name: this.$t("csnft"),
      //     tokenName: "usdt",
      //     token: "1",
      //     amount: 3000,
      //     contract: null,
      //     generation: 1,
      //     x: "1000%",
      //     loading: false,
      //     rloading: false,
      //   },
      //   {
      //     name: this.$t("lmnft"),
      //     tokenName: "usdt",
      //     token: "2",
      //     amount: 2000,
      //     contract: null,
      //     generation: 2,
      //     x: "800%",
      //     loading: false,
      //     rloading: false,
      //   },
      // ],
    };
  },
  methods: {
    getCompensate(){
      this.pool1reward_contract.methods.withdrawReward().send({from:this.global.account}).then(res=>{
        this.$toast('success')
        this.getPool1Reward()
      }).catch((err) => {
        this.$toast(err.message);
      });
    },
    getPool1Reward(){
      this.igetpool1reward_contract.methods.getUserReward(this.global.account).call().then(res=>{
        this.compensate = (res / 1e18).toFixed(4)
      })
      this.LPrewards_contract.methods.getRewards(this.global.account).call().then(res=>{
        this.lpreward = (res / 1e18).toFixed(4)
      })
      this.NFT1Rewards_contract.methods.getUserReward(this.global.account).call().then(res=>{
        this.nft1reward = (res / 1e18).toFixed(4)
      })
      this.NFT2RewardsUpdate_contract.methods.getUserReward(this.global.account).call().then(res=>{
        this.nft2reward = (res / 1e18).toFixed(4)
      })
      this.v1balanceCell_contract.methods.getUserAmt(this.global.account).call().then(res=>{
        this.v1balancereward = (res.totalAmount / 1e18).toFixed(4)
      })
    },
    //更新lp奖励
    onUpdatLpReward(){
      this.LPrewards_contract.methods.updateReward(this.global.account).send({from:this.global.account}).then(res=>{
        //  this.lpreward = (res / 1e18).toFixed(4)
         this.getPool1Reward()
        //  console.log(res);
         this.$toast(this.$t('update success'));
      }).catch((err) => {
        this.$toast(err.message);
      });
    },

    //更新联盟卡牌
    onUpdatNFT2Reward(){
      this.NFT2RewardsUpdate_contract.methods.updateReward(this.global.account).send({from:this.global.account}).then(res=>{
         this.getPool1Reward()
         this.$toast(this.$t('update success'));
      }).catch((err) => {
        this.$toast(err.message);
      });
    },

    //领取lp奖励
    onWithdrawLpReward(){
      this.LPrewards_contract.methods.withdrawReward().send({from:this.global.account}).then(res=>{
        this.$toast('success')
        this.getPool1Reward()
      }).catch((err) => {
        this.$toast(err.message);
      });
    },
    //领取NFT奖励
    onWithdrawNFT1Reward(){
      this.NFT1Rewards_contract.methods.withdrawReward().send({from:this.global.account}).then(res=>{
        this.$toast('success')
        this.getPool1Reward()
      }).catch((err) => {
        this.$toast(err.message);
      });
    },

    //领取NFT奖励
    onWithdrawNFT2Reward(){
      this.NFT2Rewards_contract.methods.withdrawReward().send({from:this.global.account}).then(res=>{
        this.$toast('success')
        this.getPool1Reward()
      }).catch((err) => {
        this.$toast(err.message);
      });
    },
    //领取v1奖励
    onWithdrawV1Balance(){
      this.v1balanceSend_contract.methods.withdraw().send({from:this.global.account}).then(res=>{
        this.$toast('success')
        this.getPool1Reward()
      }).catch((err) => {
        this.$toast(err.message);
      });
    },

    async init() {

      const balance = await this.UnicornCoreToken_contract.methods
        .balanceOf(this.global.account)
        .call();

      const unicorns = await this.UnicornCoreToken_contract.methods
        .getUnicorns(this.global.account, 0, balance)
        .call();
      const unicornsTokenIds = unicorns[0].map((e) => e.toString());

      const tokenIds = unicornsTokenIds.join(",");

      const { data } = await this.$axios.post(
        this.nftapi + "/api/nft/tokenids",
        {
          tokenids: tokenIds,
        }
      );
      data?.data?.forEach((e) => {
        const index = this.list.findIndex(
          (el) => el.generation == e.generation
        );
        if (index > -1) {
          this.$set(
            this.list,
            index,
            Object.assign(this.list[index], { isMint: true })
          );
        }
      });
    },
    onOver(){
      return this.$toast(this.$t('over'));
    },
    onApprove(item) {
      const price = (item.amount * 10 ** 18 * 100).toFixed(0);
      item.loading = true;
      this.USDT_contract.methods
        .approve(
          this.pool.Unicorn2Box,
          this.global.web3.utils.numberToHex(BigNumber(price))
        )
        .send({
          from: this.global.account,
        })
        .then(() => {
          item.loading = false;
          this.$toast("APPROVE SUCCESS");
        })
        .catch((err) => {
          this.$toast(err);
          item.loading = false;
        });
    },

    async oRelease(item) {
      try {
        item.rloading = true;
        const { data } = await this.$axios.get(
          this.apiUrl +
            `/api/box/getMintData/${item.generation}/${this.global.account}`
        );
        const {
          sign,
          tokenId,
          cid,
          capacita,
          weight,
          strength,
          endurance,
          agility,
          generation,
          bgId,
        } = data.data;
        const params = [
          tokenId,
          this.global.account,
          cid,
          [
            capacita,
            weight,
            [strength, endurance, agility],
            [0, 0, generation, bgId],
          ],
          sign,
        ];

        const methods = `blind${item.generation}box`;

        this.Unicorn2Box_contract.methods[methods](params)
          .send({ from: this.global.account })
          .then(() => {
            item.rloading = false;
            this.successShow = true
            // this.$alert(this.$t("forgingsuccess"), this.$t("success"), {
            //   confirmButtonText: this.$t("head.confirm"),
            //   callback: () => {},
            // });
            // this.$toast('SUCCESS')
          })
          .catch((err) => {
            this.$toast(err);
            item.rloading = false;
          });
      } catch (error) {
        console.log(error);
        this.$toast(error);
        item.rloading = false;
      }
    },
  },
  // blind1box((uint256,address,string,(uint256,uint256,(uint256,uint256,uint256),(uint256,uint256,uint256,uint256)),bytes))
  watch: {
    "global.account"() {
      this.Unicorn2Box_contract = new this.global.web3.eth.Contract(
        this.poolabi.unicorn2box_abi,
        this.pool.Unicorn2Box
      );

      this.USDT_contract = new this.global.web3.eth.Contract(
        this.poolabi.erc20abi,
        this.pool.USDT
      );

      this.UnicornCoreToken_contract = new this.global.web3.eth.Contract(
        this.poolabi.UnicornCoreToken_abi,
        this.pool.UnicornCoreToken
      );

      this.pool1reward_contract = new this.global.web3.eth.Contract(
        this.poolabi.pool1reward_abi,
        this.pool.pool1reward
      );

      this.igetpool1reward_contract = new this.global.web3.eth.Contract(
        this.poolabi.igetpool1reward_abi,
        this.pool.igetpool1reward
      );
      this.LPrewards_contract = new this.global.web3.eth.Contract(
        this.poolabi.LPrewards_abi,
        this.pool.LPrewards
      );

      this.NFT1Rewards_contract = new this.global.web3.eth.Contract(
        this.poolabi.NFT1Rewards_abi,
        this.pool.NFT1Rewards
      );
      this.NFT2Rewards_contract = new this.global.web3.eth.Contract(
        this.poolabi.NFT2Rewards_abi,
        this.pool.NFT2Rewards
      );
      this.NFT2RewardsUpdate_contract = new this.global.web3.eth.Contract(
        this.poolabi.NFT2RewardsUpdate_abi,
        this.pool.NFT2RewardsUpdate
      );

      this.v1balanceCell_contract = new this.global.web3.eth.Contract(
        this.poolabi.v1balanceCell_abi,
        this.pool.v1balanceCell
      );
      this.v1balanceSend_contract = new this.global.web3.eth.Contract(
        this.poolabi.v1balanceSend_abi,
        this.pool.v1balanceSend
      );

       this.getPool1Reward()
      // this.init();
    },
  },
};
</script>
<style lang="scss" scoped>
.box {
  border: 1px solid #ffffff33;
  box-sizing: border-box;
  background: radial-gradient(
    70.23% 70.23% at 50% 50%,
    #101c41 0%,
    #0e5596 100%
  );
}

.mybtn[disabled] {
  pointer-events: none;
  @apply opacity-60;
}
</style>
<template>
  <van-overlay z-index="99" :show="showModel" @click="show = false">
    <div class="flex items-center justify-center h-full w-full" @click.stop>
      <div class="h-443px w-375px mx-auto bg-img relative">
        <div class="absolute top-135px z-1 left-0 w-full">
          <img class="w-201px h-170px mx-auto" src="~@/assets/new/success.png" alt="" srcset="">
        </div>
        <div class="absolute top-208px left-0 w-full">
          <div class="w-247px h-236px mx-auto bg-white rounded-24px border-10px border-[#ffeed0]">
            <div class="text-[#333] pt-92px text-center text-17px">
                <div class="mt-6px">{{$t('forgingsuccess')}}</div>
                <div @click="show = false" class="mt-16px bg-gradient-to-l from-[#fd9025] to-[#ffcb2f] h-44px leading-44px text-15px w-147px mx-auto rounded-12px text-white">
                  {{$t('zdl')}}
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </van-overlay>
</template>
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    showModel: {
      get() {
        return this.show;
      },
      set(e) {
        this.$emit("update:show", e);
      },
    },
  },
  methods:{
    onSuccess(){
      this.showModel = false
    },
  }
};
</script>
<style lang="scss" scoped>
.bg-img{
  background: url('~@/assets/new/22.png') no-repeat;
  background-size: contain;
}
</style>